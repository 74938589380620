@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Fredoka+One&family=Nunito:wght@300;400;500;600&family=Poppins:wght@200;300;400&family=Roboto:wght@300;400;500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

:root {
  --card-height: 65vh;
  --card-width: calc(var(--card-height) / 1.5);
}

body {
}

.header {
  position: fixed;
  top: 2.6em;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgb(43 43 43 / 90%);
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.header .header-container .header-nav-list {
  font-family: "Roboto", sans-serif;
  padding: 0.5em 0.8em;
  color: white;
  font-weight: 500;
  margin: 0 0.5em;
  text-transform: uppercase;
}

.header .header-container .header-nav-list:hover {
  background: white;
  color: #f56300;
  border-radius: 30px;
}

.header .play-game {
  background: linear-gradient(180deg, #f95c08 0%, #fdaf32 100%);
  border-radius: 50px;
  color: black !important;
  padding: 0.5em 1.5em !important;
  font-family: "Poppins", sans-serif !important;
  transition: all 0.2s linear;
  border: 1px solid #ff6820;
  box-shadow: 0 1px 3px #ff8f03;
}

.header .play-game:hover {
  background: linear-gradient(180deg, #fdaf32 0%, #f95c08 100%) !important;
}

.alice-carousel {
  position: relative;
}

.alice-carousel__dots {
  margin: 0 !important;
  position: absolute;
  bottom: 1em;
  width: 100%;
}
.alice-carousel__dots-item {
  width: 15px !important;
  height: 15px !important;
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background: black !important;
}

.social-networks {
  background: #e2e2dd;
}

.social-networks .social-networks-article a {
  display: block;
}

.social-networks .social-networks-article svg {
  font-size: 2em;
  color: #e74c3c;
  margin: 0 auto;
}

.social-networks .social-networks-article:hover svg {
  animation: float 3s ease-in-out infinite;
  color: black;
}

.prev-game {
  position: fixed;
  margin: 1em;
  color: #e30049;
  text-transform: uppercase;
  font-weight: 800;
  cursor: pointer;
}

.prev-game svg {
  color: #e30049;
  font-size: 2em;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.about-shibu {
  height: 750px;
  /*background: url("../public/img/bg-about.jpg");*/
  background: radial-gradient(
    circle,
    rgba(226, 226, 226, 1) 0%,
    rgba(243, 243, 243, 1) 50%,
    rgba(223, 223, 223, 1) 100%
  );
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
}

.teams-shibu {
  background: radial-gradient(
    circle,
    rgba(226, 226, 226, 1) 0%,
    rgba(243, 243, 243, 1) 50%,
    rgba(223, 223, 223, 1) 100%
  );
  height: auto;
}

.community-shibu {
  background: radial-gradient(
    circle,
    rgba(226, 226, 226, 1) 0%,
    rgba(243, 243, 243, 1) 50%,
    rgba(223, 223, 223, 1) 100%
  );
  height: auto;
}

.community-shibu .community-article {
  background: white;
  box-shadow: 0px 0px 5px 0 rgb(0 0 0 / 25%);
}

.community-shibu .community-article img {
  display: block;
  width: 100%;
}

.community-shibu .community-article p {
  padding: 10px;
  font-weight: 700;
}

.characters-shibu {
  background: radial-gradient(
    circle,
    rgba(226, 226, 226, 1) 0%,
    rgba(243, 243, 243, 1) 50%,
    rgba(223, 223, 223, 1) 100%
  );
  height: auto;
}

.characters-shibu-article {
  margin: 1em 0 0 0;
}

.characters-shibu-article:hover img {
  filter: saturate(1.8);
}

.characters-shibu-article:hover p {
  background: #868686;
  color: white;
}

.characters-shibu-article p {
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
  background: transparent;
  border: 2px solid #000;
  box-shadow: 0 2px 8px -1px rgba(18, 22, 33, 0.04);
  border-bottom: 3px solid rgb(43 43 43 / 50%);
  /*box-shadow: 0 6px 0 #266b91, 0 8px 1px 1px rgba(0, 0, 0, 0.3),
    0 10px 0 5px #12517d, 0 12px 0 5px #1a6b9a, 0 15px 0 5px #0c405e,
    0 15px 1px 6px rgba(0, 0, 0, 0.3);*/
  color: #5b5b5b;
  /*text-shadow: 2px 2px 1px #0066a2, -2px 2px 1px #0066a2, 2px -2px 1px #0066a2,
    -2px -2px 1px #0066a2, 0px 2px 1px #0066a2, 0px -2px 1px #0066a2,
    0px 4px 1px #004a87, 2px 4px 1px #004a87, -2px 4px 1px #004a87;*/
  line-height: 1.5em;
  letter-spacing: 0.1em;
  font-family: "Roboto", sans-serif;
  border-radius: 50px;
  text-transform: uppercase;
}

.maps-shibu {
  height: 750px;
  background: radial-gradient(
    circle,
    rgba(226, 226, 226, 1) 0%,
    rgba(243, 243, 243, 1) 50%,
    rgba(223, 223, 223, 1) 100%
  );

  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
}

.tokenomics-shibu {
  background: radial-gradient(
    circle,
    rgba(226, 226, 226, 1) 0%,
    rgba(243, 243, 243, 1) 50%,
    rgba(223, 223, 223, 1) 100%
  );
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  height: auto;
}

.guide-shibu {
  background: radial-gradient(
    circle,
    rgba(226, 226, 226, 1) 0%,
    rgba(243, 243, 243, 1) 50%,
    rgba(223, 223, 223, 1) 100%
  );
  height: auto;
}

.maps-shibu .maps-shibu-list {
  width: 1024px;
  margin: 0 auto;
  position: relative;
}

.maps-shibu .maps-shibu-list .btn-prev {
  position: absolute;
  top: 50%;
  margin-top: -0.3em;
  left: -1em;
  font-size: 8em;
  opacity: 0.2;
  cursor: pointer;
}

.maps-shibu .maps-shibu-list .btn-next {
  position: absolute;
  top: 50%;
  margin-top: -0.3em;
  right: -1em;
  font-size: 8em;
  opacity: 0.2;
  cursor: pointer;
}

.maps-shibu .maps-shibu-list .alice-carousel {
  border: 11px solid #a8a8a8;
}

.maps-shibu .maps-shibu-list .alice-carousel__stage-item img {
  width: 100%;
  display: block;
}

.about-shibu::before {
  content: "";
  display: block;
  height: 10px;
  background-image: radial-gradient(
    farthest-side at 50% -50%,
    rgb(171 171 171),
    rgba(0, 0, 0, 0)
  );
  width: 100%;
  position: absolute;
  top: 0;
}

.about-shibu {
  position: relative;
}

.about-shibu .about-shibu-header h2 {
  font-size: 2.5em;
  font-family: "Raleway", sans-serif;
}

.about-shibu .about-shibu-header h2 {
  font-weight: 800;
  color: black;
  letter-spacing: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-shibu .about-shibu-header h2 b {
  background: orange;
  width: 45px;
  height: 45px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  border-radius: 25px;
  color: black;
  text-shadow: 0 0 0 black;
  letter-spacing: 0;
  font-weight: 600;
  font-family: "Nunito", cursive;
  font-size: 30px;
  margin-right: 10px;
}

.about-shibu .about-shibu-description {
  width: 100%;
  text-align: center;
  padding: 5em 0 0 0;
  position: relative;
}

.about-shibu .about-shibu-description .is-description {
  position: absolute;
  background: white;
  padding: 20px;
  border: 2px solid black;
  border-radius: 10px;
  top: 2em;
  width: 30%;
  right: 0;
  text-align: justify;
  font-family: "Roboto", sans-serif;
  box-shadow: inset 0px 2px 1px #9b9797, inset 0px 4px 1px #3f3c3c,
    inset 5px -6px 1px #7e7e7e, inset -6px -6px 1px #9b9797,
    inset 4px 2px 1px #9b9797, inset 5px 4px 1px #3f3c3c,
    inset 5px 4px 1px #7e7e7e, inset 5px 2px 1px #9b9797,
    inset 0 6px 1px #3f3c3c, inset 0 4px 1px #7e7e7e, inset 0 2px 2px #cfcfc7,
    inset 0 0 11px #9d9d9d;
}

.about-shibu .about-shibu-description .is-image img {
  margin: 0 auto;
  animation: floatAbout 6s ease-in-out infinite;
}

.about-shibu .about-shibu-header p {
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  color: #3b3b3b;
}

.about-shibu .about-shibu-history {
  position: absolute;
  background: linear-gradient(208deg, #08f9b8 0%, #32adfd 100%);
  padding: 10px 20px;
  border-radius: 20px;
  font-family: "Alfa Slab One", cursive;
  letter-spacing: 2px;
  color: white;
  top: 5em;
  border: 1px solid #16c4ff;
  box-shadow: inset 0 0 3px #00bdec;
  cursor: pointer;
  z-index: 8;
}

.about-shibu .about-shibu-history:hover {
  background: linear-gradient(108deg, #08f9b8 0%, #32adfd 100%);
}

.about-shibu .about-shibu-mission {
  position: absolute;
  top: 22em;
  background: linear-gradient(208deg, #08f9b8 0%, #32adfd 100%);
  padding: 10px 20px;
  border-radius: 20px;
  font-family: "Alfa Slab One", cursive;
  letter-spacing: 2px;
  color: white;
  border: 1px solid #16c4ff;
  box-shadow: inset 0 0 3px #00bdec;
  cursor: pointer;
  z-index: 8;
}

.about-shibu .about-shibu-mission:hover {
  background: linear-gradient(108deg, #08f9b8 0%, #32adfd 100%);
}

.about-shibu .about-shibu-vission {
  position: absolute;
  bottom: 5em;
  background: linear-gradient(208deg, #08f9b8 0%, #32adfd 100%);
  padding: 10px 20px;
  border-radius: 20px;
  font-family: "Alfa Slab One", cursive;
  letter-spacing: 2px;
  color: white;
  border: 1px solid #16c4ff;
  box-shadow: inset 0 0 3px #00bdec;
  cursor: pointer;
  z-index: 8;
}

.about-shibu .desc-details:first-child {
  margin-top: 0;
}

.about-shibu .desc-details {
  margin: 0.85em 0;
  font-size: 14px;
}

@keyframes floatAbout {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-50px);
  }
  100% {
    transform: translatey(0px);
  }
}

.game-article-screenshot {
  background: white;
  width: 30%;
  box-shadow: 0px 0px 5px 0 rgb(0 0 0 / 25%);
}

.game-article-screenshot:hover img {
  filter: grayscale(1);
  transition: all 0.5s linear;
}

.game-article-screenshot p {
  padding: 10px;
}

.social-media {
  background: #e2e2dd;
}

.social-media .media-shibu-header {
  width: 100%;
  padding: 80px 0;
}

.social-media .media-shibu-header h3 {
  font-size: 2em;
  font-family: "Raleway", sans-serif;
  color: #212224;
  font-weight: 800;
}

.social-media .media-list-icons {
  width: 100%;
}

.social-media .media-list-icons .icons-article {
  background: white;
  padding: 1em 2em;
  box-shadow: 0px 0px 5px 0 rgb(0 0 0 / 25%);
}

.guide-list .guide-list-article {
  background-color: white;
  margin: 2em 0;
  padding: 1em;
  box-shadow: 0px 0px 5px 0 rgb(0 0 0 / 25%);
}

.guide-list .guide-list-article h3 {
  font-size: 1.7em;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #565656;
}

.guide-list .guide-list-article p {
  color: #6b6b6b;
}

.guide-shibu .guide-list-action {
  background: linear-gradient(192deg, #f0f0f0, transparent);
  border: 2px solid #a5a5a5;
  color: #382b22;
  padding: 0.85em 1.5em;
  margin: 0 1em;
  text-align: center;
  border-radius: 5px;
  font-weight: 600;
}

.block-image-separate-guide {
  position: absolute;
  top: -4em;
  left: 0;
  z-index: 2;
}

.block-image-separate-guide img {
  width: 150px;
}

.block-image-separate-maps {
  position: absolute;
  top: -4em;
  left: 0;
  z-index: 2;
}

.block-image-separate-maps img {
  width: 150px;
}

.block-image-separate-tokenomics {
  position: absolute;
  top: -3em;
  right: 0;
  z-index: 2;
}

.block-image-separate-tokenomics img {
  width: 130px;
}

.block-image-separate {
  position: absolute;
  bottom: -6em;
  right: 0;
  z-index: 2;
}

.block-image-separate img {
  width: 200px;
}

.block-image-separate-game {
  position: absolute;
  top: -3em;
  right: 0;
  z-index: 2;
}

.block-image-separate-game img {
  width: 130px;
}

.teams-shibu-article {
  position: relative;
  background: white;
  box-shadow: 0px 0px 5px 0 rgb(0 0 0 / 25%);
}

.teams-shibu-article:hover img {
  filter: grayscale(1);
  transition: all 0.5s linear;
}

.teams-shibu-article p {
  font-weight: 600;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 0.3em 0;
  text-transform: uppercase;
}

.footer-landing {
  background: #191a1c;
}

.footer-landing .container {
  padding: 40px 0;
}

.footer-landing label {
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 5px;
  display: block;
}

.footer-landing .list-ul {
}

.footer-landing .list-ul-li {
  display: block;
  margin: 0.2em 0;
}

.footer-landing .list-ul-li-a {
  color: rgb(129, 142, 163);
  font-size: 14px;
  letter-spacing: 0.5px;
}

.footer-landing .footer-left {
  width: 40%;
}

.footer-landing .footer-right label {
  text-align: right;
}

.footer-landing .list-ul-social {
  margin: 1em 0;
}

.footer-landing .list-ul-social .list-ul-social-li {
  display: inline-block;
  margin: 0 1em;
}

.footer-landing .list-ul-social .list-ul-social-li .list-ul-social-li-a {
  color: rgb(129, 142, 163);
  font-size: 2em;
}

.footer-landing .footer-right .list-ul-li {
  text-align: right;
}

.bm-menu-wrap {
  background-color: #e2e2dd;
  top: 0;
}

.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-item-list a {
  padding: 0.8em;
  font-weight: 600;
}

.bm-menu a {
  color: #646464;
}

.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}

.header-mobile {
  display: none;
  position: fixed;
  top: 0;
  background: white;
  z-index: 99;
  left: 0;
  right: 0;
  padding: 10px;
  box-shadow: 0 0 1px black;
}

.header-mobile article a {
  display: block;
  width: 50%;
  margin: 0 auto;
}

.header-mobile article a img {
  margin: 0 auto;
}

.bm-overlay {
  top: 0;
  left: 0;
}

.carousel-root {
  display: none;
}

.tokenomics-container {
  position: relative;
}

.tokenomics-container .tokenomics-pie-chart {
  width: 430px;
}

.tokenomics-container .tokenomics-pie-info:before {
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  height: 300px;
  background-color: #dddddd;
  left: 50%;
  top: 50%;
  margin-top: -6em;
}
.tokenomics-container .tokenomics-pie-info {
  width: 500px;
}

.tokenomics-container .tokenomics-pie-info h2 {
  font-family: "Roboto", sans-serif;
  color: #6b6b6b;
  text-transform: capitalize;
  text-align: justify;
}

.tokenomics-container .tokenomics-pie-info h3 {
  margin: 0 0 2em 0;
  color: #777777;
  background: white;
  padding: 1em;
  border: 1px solid #dedede;
}

.white-paper {
  margin: 0 auto;
  height: 50px;
  width: 250px;
  display: grid;
  place-items: center;
  position: relative;
  cursor: pointer;
  border: 2px solid #fa7d18;
  background: white;
  border-radius: 9999px;
  box-shadow: 7px 7px 10px #00000018;
}

.white-paper:hover{
  background: #eeeeee;
}

.maps-shibu-list {
  display: block;
}

.maps-shibu-list-mobile {
  display: none;
  padding: 1em;
}

.alice-carousel__stage-item{
  width: 100%!important;
}

.warning-buy{
  position: fixed;
  top:0;
  left:0;
  right: 0;
  background:#03a9f4;
  z-index:999;
  text-align: center;
  color:white;
  padding: 0.25em 0;
}

.warning-buy h3{
  display: flex;
  justify-content: center;
  align-items: center;
}

.warning-buy h3 a.uniswap{
  color:black;
  font-weight: 700;
}

.warning-buy h3 a.pancakes{
  color:black;
  font-weight: 700;
}

.warning-buy a{
  display: inline-block;
  margin: 0 0.5em;
}

.warning-buy a.ethereum{
  display: inline-block;
  margin: 0 0.5em;
  background: black;
  border-radius:10px;
  padding:5px 10px;
}

.warning-buy a.bnb{
  display: inline-block;
  margin: 0 0.5em;
  background: black;
  border-radius:10px;
  padding:5px 10px;
}

.warning-buy a b{
  display: flex;
  align-items: center;
}

.warning-buy a b img{
  padding-right: 2px;
}

.game-article-screenshot-lvl{
    width: 12%;
    background: white;
    box-shadow: 0px 0px 5px 0 rgb(0 0 0 / 25%);
}

.game-article-screenshot-lvl img{
  width: 100%;
}

@media screen and (min-width: 0) and (max-width: 760px) {

  .warning-buy{
    bottom: 0;
    top: inherit;
    font-size: 14px;
    padding: 0.5em;
    display: none;
  }

  .warning-buy h3{
    flex-wrap: wrap;
  }
  .warning-buy a.bnb,
  .warning-buy a.ethereum{
    padding:2px 10px!important;
  }

  .game-article-screenshot-lvl{
    width: 30%;
    margin: 0 0 2em 0;
  }

  .tokenomics-container .tokenomics-pie-info h2 {
    font-size: 14px;
  }
  .maps-shibu-list {
    display: none;
  }

  .maps-shibu-list-mobile {
    display: block;
  }

  .tokenomics-container {
    padding: 1em;
    flex-wrap: wrap;
  }

  .tokenomics-container .tokenomics-pie-chart {
    width: 100%;
  }

  .tokenomics-container .tokenomics-pie-info {
    width: 100%;
    padding-top: 2em;
  }

  .tokenomics-container .tokenomics-pie-info:before {
    display: none;
  }

  .maps-shibu,
  .teams-shibu,
  .guide-shibu,
  .community-shibu {
    background: #e2e2dd;
  }

  .guide-list {
    padding: 0.85em;
    font-size: 0.85rem;
  }

  .alice-carousel {
    display: none;
  }

  .carousel-root {
    display: block;
  }

  .header-mobile {
    display: block;
  }

  .social-networks .list-social {
    flex-wrap: wrap;
  }

  .social-networks .social-networks-article {
    width: 50%;
    text-align: center;
    padding: 1em 0;
  }
  .about-shibu {
    height: auto;
  }
  .about-shibu .about-shibu-description .is-description {
    width: 90%;
    position: static;
    margin: 0 auto;
  }

  .about-shibu .about-shibu-history,
  .about-shibu .about-shibu-mission,
  .about-shibu .about-shibu-vission {
    display: none;
  }

  .block-image-separate {
    bottom: -3em;
  }
  .block-image-separate img {
    width: 115px;
  }

  .characters-shibu .list-characters {
    flex-wrap: wrap;
  }

  .characters-shibu .list-characters div.container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .about-shibu .about-shibu-header h2 b {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .about-shibu .about-shibu-header h2 {
    font-size: 1.6em;
  }
  .block-image-separate-maps img {
    width: 115px;
  }
  .block-image-separate-maps {
    top: -2em;
  }
  .block-image-separate-game img {
    width: 92px;
  }
  .maps-shibu .maps-shibu-list {
    width: 100%;
  }

  .block-image-separate-game {
  }

  .community-shibu .list-game div.container {
    flex-wrap: wrap;
  }

  .game-article-screenshot {
    width: 100%;
    margin: 0 0 1em 0;
  }

  .block-image-separate-maps img {
    width: 105px;
  }

  .block-image-separate-tokenomics img {
    width: 80px;
  }

  .block-image-separate-tokenomics {
    top: -2em;
  }

  .list-teams div.container {
    padding: 0;
  }
  .block-image-separate-guide img {
    width: 110px;
    margin-top: 1em;
  }
  .guide-shibu div.container div.flex {
    flex-wrap: wrap;
  }
  .guide-shibu .guide-list-action {
    width: 90%;
    justify-content: center;
    margin: 0 0 1em 0;
  }
  .community-shibu div.flex {
    flex-wrap: wrap;
  }
  .social-media .media-list-icons .icons-article {
    margin: 0 auto 1em auto;
    width: 90%;
  }

  .social-media .media-list-icons .icons-article img {
    margin: 0 auto;
  }

  .maps-shibu .maps-shibu-list .btn-prev {
    display: none;
  }

  .maps-shibu .maps-shibu-list .btn-next {
    display: none;
  }
  header.header {
    display: none;
  }

  .footer-landing .footer-left {
    width: 100%;
    flex-wrap: wrap;
  }

  .footer-landing-list {
    width: 100%;
    text-align: center;
    margin: 0 0 1.5em 0;
  }

  .footer-right {
    width: 100%;
  }

  .footer-landing .footer-right label,
  .footer-landing .footer-right .list-ul-li {
    text-align: center;
  }

  .social-media .media-shibu-header h3{
    font-size: 1.5em;
  }
}

@media screen and (min-width: 500px) and (max-width: 760px) {

  .item-community{
    width: 30%;
    padding:0;
  }

  .social-media .media-list-icons .icons-article{
    width:40%;
  }

  .media-list-icons .icons-article:nth-child(5){
    width:90%;
  }
  
  .community-shibu .community-article p{
    font-size:13px;
  }

  .tokenomics-container .tokenomics-pie-chart{
    width: 50%;
    margin: 0 auto;
  }
  .social-networks .social-networks-article{
    width: 25%;
  }

  .characters-shibu-article p{
    font-size: 1em;
  }

  .characters-shibu .list-characters div.container{
    width: 30%;
  }

  .game-article-screenshot img{
    display:block;
    width: 100%;
  }

  .game-article-screenshot{
    width: 45%;
  }

  .teams-shibu .list-teams{
    display: flex;
    flex-wrap: wrap;
  }

  .list-teams div.container{
    width: 50%;
  }
}

@media screen and (min-width: 760px) and (max-width: 1020px) {
  .header {
    display:none;
  }

  .header-mobile{
    display:block;
  }

  .about-shibu .about-shibu-description .is-description{
    width: 35%;
    top: 0.5em;
  }

  .about-shibu{
    padding-left: 1em;
    padding-right: 1em;
  }

  .block-image-separate img{
    width: 150px;
  }

  .block-image-separate{
    bottom: -5em;
  }

  .characters-shibu-article p{
    font-size: 1em;
  }

  .characters-shibu-article{
    width: 80%;
  }

  .maps-shibu .maps-shibu-list{
    width: 80%;
  }

  .maps-shibu .maps-shibu-list .alice-carousel__stage-item img{
    width: 100%;
  }

  .maps-shibu{
    height: auto;
  }

  .tokenomics-container .tokenomics-pie-info:before{
    display: none;
  }

  .maps-shibu .maps-shibu-list .btn-next,
  .maps-shibu .maps-shibu-list .btn-prev{
    font-size: 5em;
  }

  .social-media .media-list-icons .icons-article{
    width: 18%;
  }

  .social-media .media-list-icons{
    padding-left: 1em;
    padding-right: 1em;
  }

  .tokenomics-container .tokenomics-pie-chart{
    width: 40%;
  }

  .footer-landing .container{
    padding-left: 1em;
    padding-right: 1em;
  }

} 

@media screen and (min-width: 1020px) and (max-width: 1210px) {
  .header-nav{
    font-size: 0.8em;
  }
  .block-image-separate img{
    width: 150px;
  }
  .maps-shibu .maps-shibu-list .btn-next{
    display: none;
  }
  .maps-shibu .maps-shibu-list .btn-prev{
    display: none;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 24px;
  right: 0;
  top: 0;
  margin: 0.75em;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ababab;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #f68018;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #858585;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.carousel-root .slider {
  margin-top: 1.5em;
}

.carousel-root .control-arrow {
  display: none;
}

.slider-item-1 {
  background: url("https://shibauniverse.vercel.app/img/banner-shiba-1.jpg");
  background-size: cover;
  background-position: center;
  height: 350px;
}

.slider-item-2 {
  background: url("https://shibauniverse.vercel.app/img/banner-shiba-2.jpg");
  background-size: cover;
  background-position: center;
  height: 350px;
}

.slider-item-3 {
  background: url("https://shibauniverse.vercel.app/img/banner-shiba-3.jpg");
  background-size: cover;
  background-position: center;
  height: 350px;
}

.carousel-status,
.thumbs-wrapper {
  display: none;
}
