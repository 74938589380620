.play-game{
    background:radial-gradient(circle farthest-side at center,#2c2c2c 0,#141414 100%);
}

.account-access{
    background:transparent;
    padding: 1em 1em 300px 1em;
}

.play-game .react-tabs__tab--selected{
    background: transparent;
    color:white;
    border: none;
    font-weight: 700;
    border-bottom: 4px solid orange;
}

.play-game .react-tabs__tab{
    color:white;
}

.play-game .react-tabs__tab-list{
    border-bottom:none;
}

.play-game .form_input input{
    background-color: rgba(12,12,12,.7);
    border: none;
    padding: 0.85em 1em;
    display: block;
    width: 100%;
    border:1px solid hsla(0,0%,100%,.25);
    color:white;
}

.play-game .form_input span{
    color:hsla(0,0%,100%,.5);
    font-weight: 300;
    font-size: 14px;
}

.play-game .form_input input[type='submit']{
    width: 50%;
    margin: 0 auto;
    color: #262626;
    background:white;
    font-weight: 600;
    cursor:pointer;
}

.play-game .form_input input[type='submit']:hover{
    background-color: #0078ff;
    border-color: #0078ff;
    color: #fff;
}

.play-game .form_input .ReactFlagsSelect-module_flagsSelect__2pfa2{
    background-color: rgba(12,12,12,.7);
    padding:0;
}

.play-game .form_input .ReactFlagsSelect-module_selectOptions__3LNBJ{
    background:black;
    border: none;
}

.play-game .ReactFlagsSelect-module_selectOption__3pcgW:hover, .ReactFlagsSelect-module_selectOption__3pcgW:focus{
    background: #252525;
}

.play-game .ReactFlagsSelect-module_filterBox__3m8EU{
    padding:0;
    background: transparent;
}

.play-game .ReactFlagsSelect-module_filterBox__3m8EU input{
    padding:0.2em 1em;
    margin:0;
    border:none;
    background-color: #161616;
}

.play-game .ReactFlagsSelect-module_selectBtn__19wW7{
    border: none;
    border:1px solid hsla(0,0%,100%,.25);
}

.play-game .react-tabs__tab-panel{
    position: relative;
}

.play-game .loaderTab{
    position:absolute;
    width: 100%;
    text-align:center;
    top: 50%;
    margin-top: -3em;
}
.play-game .loaderTab > div{
    justify-content: center;
}

.play-game .react-tabs{
    position: relative;
}

.tag-info-data{
    font-size: 12px!important;
    line-height: 1.5em;
}

.tag-info-data a{
    text-decoration: underline;
}

.footer-static-copyright{
    background-color: black;
    text-align: center;
    color:hsla(0,0%,40%,.5);
    padding: 1em 0;
}